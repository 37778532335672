$font-stack: Lato, sans-serif;
$bg-color: #333;
$red:   #F65C54;
$green: #43B39B;
$blue:  #69AAE0;


.btn1 {
 
  color: #B4B4B4 !important;
  min-width: 128px;
  height: 36px;
  font-size: 14px;
  font-family: OpenSans-Regular;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

 
    
    &--no  {
      background-color: #fff;
    }
    
    &--yes {
    
      // Yes is the default button style
    }
    
   
    &--right {
      float: right;
      margin-right: 0;
    }
    
    &:hover,
    &:focus {
      opacity: 1;
      outline: none;
    }
  }
  

  
  // On focus (keyboard navigation), show
  // the expected outline:
  input:focus + label.btn1 {
      outline: thin dotted;
  }
  
  // Highlight fully when checked:s
  input:checked + label.btn1 {
    background: #E2F9FF;
    border: 1px solid #2DC3E8;
    color: #2DC3E8 !important;
    font-family: OpenSans-SemiBold;
  }
  
  .is-hidden {
    opacity: 0;
    position: absolute;
  }

  .backgroundCRD{
    background-color: #FFF8F8 !important;
  }