/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.invalid-feedback {
  text-transform: capitalize !important;
  font-size: 10px;
  display: block !important;
}
.form-text {
  font-size: 10px;
}
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.form-control.is-warning {
  border: 1px solid rgba(255, 133, 133, 0.37);
  background-color: #fff8f8;
}

.inn-view img {
  max-height: 80px;
  height: 80px;
}
.eSignTextImageContainer {
  display: flex;
  justify-content: center;
  width: 250px;
  max-width: 250px;
  border: 2px solid grey;
  border-radius: 10px;
  padding: 5px;
}

.eSignTextImage {
  width: auto;
  max-width: 200px;
  height: 50px;
}
/*
Fonts
*/

.nautigalFont {
  font-family: "The Nautigal", cursive;
}

.islandMomentsFont {
  font-family: "Island Moments", cursive;
}

.cedarvilleFont {
  font-family: "Cedarville Cursive", cursive;
}

.licoriceFont {
  font-family: "Licorice", cursive;
}

.dancingScriptFont {
  font-family: "Dancing Script", cursive;
}

.pacificoFont {
  font-family: "Pacifico", cursive;
}

.qwitcherGrypenFont {
  font-family: "Qwitcher Grypen", cursive;
}

.vujahdayScriptFont {
  font-family: "Vujahday Script", cursive;
}

.waterFallFont {
  font-family: "Waterfall", cursive;
}

.yellowTailFont {
  font-family: "Yellowtail", cursive;
  max-height: 60px;
  height: 60px;
  width: auto;
}

ul {
  list-style-type: none;
}

.cursor-pointer {
  cursor: pointer;
}

.success-circle {
  background-color: #22abd7;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-icon {
  color: honeydew;
  font-weight: bold;
}

.congrats {
  color: #22abd7;
  margin-top: 4px;
  margin-bottom: 10px;
}

.screen-h {
  height: 100vh;
}
.success-container {
  /* position: relative; */
}
.application-container {
  padding: 10px 20px 10px 20px;
  border: 1.5px dashed rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 160px;
  max-width: 200px;
  height: 90px;
  max-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 5px 10px 5px 10px;
}

.count {
  text-align: center;
  font-size: 18px;
  color: grey;
  font-weight: normal;
}
.status-text {
  font-size: 12px;
  color: grey;
  font-weight: 400;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-40 {
  margin-top: 40px;
}
.doc-upload-text {
  color: #22abd7;
  text-decoration: underline;
}
.grey {
  color: grey;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100vw;
  background: #f5fdff;
  padding: 10px;
  height: 75px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.view-consent-button {
  margin: 2px 4px 2px 4px;
  border: 2px solid #22abd7;
  background: #f5fdff;
  border-radius: 5px;
  padding: 8px;
  width: 180px;
  max-width: 180px;
}
.view-consent-text {
  font-size: 16px;
  font-weight: 600;
}
.view-application-button {
  width: 180px;
  height: 36.39px;
  left: 1029.32px;
  top: 664.01px;

  background: #2dc3e8;
  border-radius: 4px;
}
.view-application-text {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.react-datepicker-wrapper {
  width: 100%;
  background: #fff !important;
}
