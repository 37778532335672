@font-face{
    font-family: Roboto-Bold;
    src: url('../fonts/Roboto-Bold.ttf');
}
@font-face{
    font-family: Roboto-Medium;
    src: url('../fonts/Roboto-Medium.ttf');
}
@font-face{
    font-family: Roboto-Regular;
    src: url('../fonts/Roboto-Regular.ttf');
}
@font-face{
    font-family: Segoe-UI-Bold;
    src: url('../fonts/Segoe-UI-Bold.ttf');
}
@font-face{
    font-family: Segoe-UI;
    src: url('../fonts/Segoe-UI.ttf');
}
@font-face{
    font-family: seguisb;
    src: url('../fonts/seguisb.ttf');
}
